import Reducer from './Reducer';

const reducerName = 'EntryState';

export const DEFAULT_STORE = {};

export const ACTION_TYPES = {
  updateStore: `update${reducerName}Store`,
  resetStore: `reset${reducerName}Store`,
};

export default new Reducer(DEFAULT_STORE, ACTION_TYPES);
