import AsyncStorage from '@react-native-async-storage/async-storage';
import { configureStore } from '@reduxjs/toolkit';
import permissions from 'reducers/permissions';
import themes from 'reducers/themes';
import home from 'reducers/home';
import entry from 'reducers/entry';
import search from 'reducers/search';
import shared from 'reducers/shared';
import user from 'reducers/user';
import settings from 'reducers/settings';
import entrystate from 'reducers/entrystate';
import client from 'reducers/client';
import csvimport from 'reducers/csvimport';
import customizations from 'reducers/customizations';
import modules from 'reducers/modules';
import releaseInfo from 'reducers/releaseInfo';
import { combineReducers } from 'redux';
import { persistReducer, persistStore } from 'redux-persist';

const reducers = combineReducers({
  user,
  home,
  permissions,
  settings,
  search,
  shared,
  entry,
  entrystate,
  client,
  csvimport,
  customizations,
  modules,
  releaseInfo,
  themes,
});

const rootReducer = (state, action) => {
  if (action.type === 'USER_LOGOUT') {
    state = undefined;
  }
  return reducers(state, action);
};

const persistConfig = {
  key: 'root',
  storage: AsyncStorage,
  blacklist: ['home'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
});

let persistor = persistStore(store);

export { store, persistor };
