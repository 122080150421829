import { singletonInitializer } from 'utils/helpers';
import BaseService from './BaseService';

const bankService = null;

class BankService extends BaseService {
  constructor() {
    super('bank');
  }
}

export default singletonInitializer(bankService, BankService);
