import { singletonInitializer } from 'utils/helpers';
import BaseService from './BaseService';

const Area = null;

class AreaService extends BaseService {
  constructor() {
    super('area');
  }
}

export default singletonInitializer(Area, AreaService);
