import produce from 'immer';
import { cloneDeep } from 'lodash';

export default function Reducer(DEFAULT_STORE, ACTION_TYPES) {
  return (state = DEFAULT_STORE, action) => {
    switch (action.type) {
      case ACTION_TYPES.updateStore: {
        const newState = produce(state, (draftState) => {
          const keysToUpdate = action?.payload && Object.keys(action.payload);
          keysToUpdate?.forEach((key) => {
            draftState[key] = action.payload[key];
          });
        });
        return newState;
      }
      case ACTION_TYPES.resetStore: {
        return cloneDeep(DEFAULT_STORE);
      }
      default:
        return state;
    }
  };
}
