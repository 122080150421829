import axios from 'axios';
import { ApiClient } from '../../api';
import { useAxiosPrivate } from './createAxiosPrivate';
const refreshTokenAPI = '/jwt/refresh';

ApiClient?.setTenentBaseUrl(
  window?.App.urls.pillPlusService,
  window?.location.hostname.split('.')[0],
);

const requestOptions = {
  baseURL: axios.defaults.baseURL,
  timeout: 300000,
  headers: {
    'Content-Type': 'application/json',
  },
};

function getCurrentAccessToken() {
  return localStorage.getItem('authorization');
}

function setRefreshedToken(token) {
  token && localStorage.setItem('authorization', token);
}

export const axiosPrivate = useAxiosPrivate({
  refreshTokenAPI,
  requestOptions,
  getCurrentAccessToken,
  setRefreshedToken,
});
