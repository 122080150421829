import axios from 'axios';

// Global variables
let failedQueue = [];
let isRefreshing = false;

// Request API Pipeline
const processQueue = (error) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve();
    }
  });

  failedQueue = [];
};

// Private API - Axios Request Handler Hook
export function useAxiosPrivate({
  refreshTokenAPI,
  requestOptions,
  getCurrentAccessToken,
  setRefreshedToken,
}) {
  // Initialize custom axios handler client
  const privateClient = axios.create(requestOptions);

  // Request Interceptor
  privateClient.interceptors.request.use(
    (config) => {
      if (config.authorization !== false) {
        const token = getCurrentAccessToken();
        if (token) {
          config.headers.Authorization = token;
        }
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    },
  );

  // Response Interceptor
  privateClient.interceptors.response.use(
    (response) => {
      return response;
    },

    async (error) => {
      // Relogin - when Refresh token is expired!
      if (error.response?.status === 403) {
        parent.location.hash = 'token_expired';
        return;
      }

      // Throw prompt on server error response!
      if (
        error?.response?.data?.code !== 401 &&
        error?.response?.data?.errorMessage !==
          'You were logged out !Please Login again'
      )
        return error?.response;

      // Process API request with pipeline - when Access token is expired with valid refresh token is available.
      const originalRequest = error.config;
      originalRequest.headers = JSON.parse(
        JSON.stringify(originalRequest.headers || {}),
      );

      // Logout when error
      const handleError = (error) => {
        parent.location.hash = 'token_expired';
        processQueue(error);
        return Promise.reject(error);
      };

      // Refresh token conditions
      if (
        error.response?.status === 401 &&
        error.response.data.errorMessage ===
          'You were logged out !Please Login again' &&
        originalRequest?.url !== originalRequest?.baseURL + refreshTokenAPI &&
        originalRequest?._retry !== true
      ) {
        if (isRefreshing) {
          try {
            await new Promise(function (resolve, reject) {
              failedQueue.push({ resolve, reject });
            });
            return await privateClient(originalRequest);
          } catch (err) {
            return await Promise.reject(err);
          }
        }
        isRefreshing = true;
        originalRequest._retry = true;
        const config = {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: getCurrentAccessToken(),
          },
          withCredentials: true,
        };

        // Get new access token and call API pipeline
        return privateClient
          .get(refreshTokenAPI, config)
          .then((res) => {
            const tokens = {
              accessToken: res?.data?.access,
            };
            setRefreshedToken(tokens.accessToken);
            processQueue(null);
            return privateClient(originalRequest);
          }, handleError)
          .catch((error) => {
            return handleError(error);
          })
          .finally(() => {
            isRefreshing = false;
          });
      }

      // Refresh token missing or expired => logout user...
      if (
        error.response?.status === 403 &&
        error.response?.data?.message ===
          'You were logged out !Please Login again'
      ) {
        return handleError(error);
      }

      // Default error handler
      return Promise.reject(error);
    },
  );

  return privateClient;
}
