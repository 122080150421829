// ---------------------------------------------------------------
// NEW CODE
// ---------------------------------------------------------------

import axios from 'axios';
import { axiosPrivate } from './axios/axiosPrivate';

export async function parseJsonResponse(response) {
  if (!response?.errorMessage) {
    window.json = response;
    return response;
  }
  const error = new Error(response?.errorMessage);
  error.isFromServer = true;
  error.response = response;
  error.message = response?.errorMessage;
  error.errors = response?.error;
  error.responseJson = response;
  console.log('jsonerror: %o', response?.errorMessage);
  throw new Error(response?.errorMessage);
}

export default async (
  path,
  body,
  method = 'POST',
  headers = {},
  cancelToken = '',
) => {
  const uri = `${axios.defaults.baseURL}${path.replace('api', '')}`;

  const response = await axiosPrivate({
    method: method,
    url: uri,
    data: body,
    cancelToken: cancelToken,
  });

  return parseJsonResponse(response?.data);
};
export const uri = 'http://localhost:8000';

// ---------------------------------------------------------------

// OLD CODE
// import axios from 'axios';
// import { useLogoutHook } from 'components/Layout/LogoutHook';
// import history from 'utils/history';

// export async function parseJsonResponse(response) {
//   // let json = null;
//   // try {
//   //   json = await response.json();
//   // } catch (e) {
//   //   // TODO Do something if response has no, or invalid JSON
//   //   console.error('json parse error: %o', e);
//   // }
//   if (response) {
//     window.json = response;
//     return response;
//   }
//   const error = new Error(response.statusText);
//   error.isFromServer = true;
//   error.response = response;
//   error.message = response?.message;
//   error.errors = response?.errors;
//   error.responseJson = response;
//   console.log('jsonerror: %o', response?.errorMessage);
//   throw new Error(response?.errorMessage);
// }

// export const getClientSystemInformation = async (
//   path,
//   body,
//   method = 'GET',
//   headers = {},
// ) => {
//   const uri = `http://localhost:8888/${path}`;

//   headers = {
//     Accept: 'application/json',
//     'Content-Type': 'application/json',
//     ...headers,
//   };
//   let fetchProperties = {
//     method,
//     headers,
//   };
//   if (body) {
//     fetchProperties = { ...fetchProperties, body: JSON.stringify(body) };
//   }
//   try {
//     const response = await fetch(uri, fetchProperties);
//     const data = await response.json();
//     if (data?.newToken) {
//       localStorage.setItem('authorization', response.data?.['newToken']);
//     } else if (data.errorMessage == 'You were logged out !Please Login again') {
//       parent.location.hash = 'token_expired';
//     } else {
//       return parseJsonResponse(data);
//     }
//     // return parseJsonResponse(data);
//   } catch (e) {
//     // TODO Do something if response has no, or invalid JSON
//     console.error('json parse error: %o', e);
//   }
// };

// export default async (path, body, method = 'POST', headers = {}) => {
//   const uri = `${axios.defaults.baseURL}${path.replace('api', '')}`;
//   const token = localStorage.getItem('authorization');

//   if (token) {
//     headers = { ...headers, Authorization: `${token}` };
//   }
//   // eslint-disable-next-line no-param-reassign
//   headers = {
//     Accept: 'application/json',
//     'Content-Type': 'application/json',
//     ...headers,
//   };
//   let fetchProperties = {
//     method,
//     headers,
//   };
//   if (body) {
//     fetchProperties = { ...fetchProperties, body: JSON.stringify(body) };
//   }
//   try {
//     const response = await fetch(uri, fetchProperties);
//     const data = await response.json();
//     if (data?.newToken) {
//       localStorage.setItem('authorization', data?.newToken);
//     } else if (data.errorMessage == 'You were logged out! Please Login again') {
//       parent.location.hash = 'token_expired';
//     } else {
//       return parseJsonResponse(data);
//     }
//   } catch (error) {
//     console.error('fetch error %o', error);
//   }

//   // if (response?.status === 401) {
//   //   response.json().then((json) => {
//   //     if (json.errorMessage == 'You were logged out !Please Login again') {
//   //       parent.location.hash = 'token_expired';
//   //     }
//   //   });
//   // } else {
//   //   return parseJsonResponse(response);
//   // }
// };
// export const uri = 'http://localhost:8000';

// ---------------------------------------------------------------
