let authorization = null;

export default function authenticate(params = {}) {
  const { route } = params;
  let status = true;
  if (typeof window !== 'undefined' && localStorage !== 'undefined') {
    if (authorization === null) {
      authorization = localStorage.getItem('authorization');
    }
    if (route) {
      if (
        route.authenticate === undefined ||
        route.authenticate ||
        (route.authenticate === undefined && route.parent.authenticate)
      ) {
        status = !!authorization;
      }
    } else if (!authorization) {
      status = false;
    }
  }

  return status;
}
