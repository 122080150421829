import { singletonInitializer } from 'utils/helpers';
import axios from 'axios';
import BaseService from './BaseService';

const User = null;
class UserService extends BaseService {
  constructor() {
    super('User');
  }

  login = async (data) => {
    return await axios.post('/auth/login', JSON.stringify(data), {
      headers: { 'Content-Type': 'application/json' },
      withCredentials: true,
    });
  };

  verify_otp = (data) => {
    return axios.post(`/auth/verify_login_otp`, data);
  };

  me = () => {
    return axios.get(`/auth/users/me`);
  };
}

export default singletonInitializer(User, UserService);
