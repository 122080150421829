import axios from 'axios';

/*
  POST request pattern
      get: (formData, config)
  GET request pattern
      create: (queryParams, config)
  PUT request pattern
      update: (resourceId, formData, config)
*/

export default class BaseService {
  constructor(service) {
    this.service = service || '';
  }

  get = (params = {}, config = {}) => {
    return axios.get(`/${this.service}_list`, { params }, config);
  };

  create = (data = {}, config = {}) => {
    return axios.post(`/${this.service}_details/`, data, config);
  };

  update = (id, data = {}, config = {}) => {
    return axios.put(`/${this.service}_details/${id}`, data, config);
  };

  delete = (id, config = {}) => {
    return axios.delete(`/${this.service}_details/${id}`, config);
  };

  handleErrors = error => {
    if (error) {
      console.error(error.response);
      if (error.response && error.response.data) {
        return error.response.data;
      }
      return { errors: { _message: error.message } };
    }
    console.error(error);
    return { errors: { _message: 'Something went wrong! please try again' } };
  };

  handleResponse = response => {
    //   if (response.data) {
    //     console.log(response.data);
    //     return response.data;
    //   }
    //   console.log(response);
    //   return {};
    return response;
  };
}
