import axios from 'axios';
import { routes } from 'utils/viewConfig';
import { singletonInitializer, replaceSubDomain } from 'utils/helpers';
import apiEndPoints from './endPoints';

const apiClient = null;

class ApiClient {
  init = (params = {}) => {
    const tenent = params.tenent || localStorage.getItem('tenent');
    if (params.baseUrl && tenent) {
      this.setTenentBaseUrl(params.baseUrl, tenent);
    }
    if (params.authorization) {
      this.setHeaders(params);
    }
    this.setResponseInterceptor();
  };

  setTenentBaseUrl = (baseUrl, tenent) => {
    axios.defaults.baseURL = `${replaceSubDomain(baseUrl, tenent)}/api`;
  };

  setHeaders = (headers = {}) => {
    axios.defaults.headers.common = {
      ...axios.defaults.headers.common,
      Authorization: `Bearer ${headers.authorization}`,
    };
    this.setErrorInterceptor();
  };

  setResponseInterceptor = () => {
    axios.interceptors.response.use(
      (response) => {
        // console.log('resonse', response);
        return response.data || {};
      },
      (error) => Promise.reject(error),
    );
  };

  setErrorInterceptor = () => {
    axios.interceptors.response.use(
      (response) => response,
      (error) => {
        console.error('response error', error);
        if (error.response && error.response.status === 401) {
          let url = error.response.request
            ? error.response.request.responseURL
            : null;
          if (url) {
            url = new URL(url);
            if (url && url.pathname !== apiEndPoints.login) {
              window.location.href = routes.login;
            }
          }
        }
        if (error.response && error.response.status === 403) {
          window.location.href = routes.accessDenied;
        }
        return { errors: error?.response?.data || {} };
      },
    );
  };

  // sample query to check base url and headers
  sampleQuery = () => {
    axios.get('/sample-query');
  };
}

export default singletonInitializer(apiClient, ApiClient);
