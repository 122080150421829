import React from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import StyleContext from 'isomorphic-style-loader/StyleContext';
import { store, persistor } from 'store';
import ApplicationContext from './ApplicationContext';
import { PersistGate } from 'redux-persist/integration/react';
import { SnackbarProvider } from 'notistack';
import { Fade } from '@material-ui/core';

export default function App({ context, insertCss, children }) {
  // NOTE: If you need to add or modify header, footer etc. of the app,
  // please do that inside the Layout component.
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <StyleContext.Provider value={{ insertCss }}>
          <SnackbarProvider
            maxSnack={1}
            autoHideDuration={1500}
            TransitionComponent={Fade}
            transitionDuration={1500}
          >
            <ApplicationContext.Provider value={{ context }}>
              {React.Children.only(children)}
            </ApplicationContext.Provider>
          </SnackbarProvider>
        </StyleContext.Provider>
      </PersistGate>
    </Provider>
  );
}

App.propTypes = {
  insertCss: PropTypes.func.isRequired,
  context: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    query: PropTypes.object,
    // urls: PropTypes.object.isRequired,
    user: PropTypes.object,
  }).isRequired,
  children: PropTypes.element.isRequired,
};
