let tenent = null;

export function singletonInitializer(ref, Service) {
  let newService = ref;
  if (!newService && Service) {
    newService = new Service();
  }
  return newService;
}

export function isValue(value) {
  return !['', undefined, null].includes(value);
}

export function fieldValidator(field, value) {
  let message = null;
  if (!isValue(value) || (Array.isArray(value) && value.length === 0)) {
    message = 'Required';
  } else if (
    field.type === 'email' &&
    value &&
    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
  ) {
    message = 'Invalid email address';
  } else if (field.type === 'tel' && value && !value.match(/^\d{10}$/)) {
    message = 'Invalid Mobile Number';
  }
  return message;
}

export function formValidator(fields, formData) {
  const fieldErrors = {};
  let validationStatus = true;
  fields.forEach((field) => {
    if (
      field.required === true ||
      (typeof field.required === 'function' && field.required(formData))
    ) {
      fieldErrors[field.key] = fieldValidator(field, formData[field.key]);
      if (fieldErrors[field.key]) {
        validationStatus = false;
      }
    }
  });
  return { fieldErrors, validationStatus };
}

export function replaceSubDomain(url, subdomain) {
  return url.replace(/^(https?:\/\/)()?([^.])*/, `$1$2${subdomain}`);
}

export function getTenent() {
  if (tenent) {
    return tenent;
  }
  if (typeof window !== 'undefined') {
    tenent = window?.location?.host?.split('.');
    tenent = tenent[1] ? tenent[0] : null;
    if (tenent?.toLowerCase() !== 'www') {
      localStorage.setItem('tenent', tenent);
    } else {
      tenent = null;
    }
    return tenent;
  }
  return null;
}
export const arrayToObject = (array, key = null) => {
  if (key === null)
    return array.reduce((obj, item) => {
      obj[item] = item;
      return obj;
    }, {});
  return array.reduce((obj, item) => {
    obj[item[key]] = item;
    return obj;
  }, {});
};

export function getDefaultValuesForFormFields(fields) {
  const defaultValues = {};
  fields.forEach((fieldDetails) => {
    defaultValues[fieldDetails.key] =
      fieldDetails.defaultValue !== undefined ? fieldDetails.defaultValue : '';
  });
  return defaultValues;
}

export function floatToFixed(value, limit = 2) {
  return parseFloat(value).toFixed(limit);
}

export function percentCalc(val1, val2, uptoDecimal = 2) {
  try {
    return val2 > 0 ? (parseFloat((val1 - val2) / val2) * 100).toFixed(2) : 0;
  } catch (e) {
    return undefined;
  }
}

// export function convertNumToWords(amount) {
//   var words = new Array();
//   words[0] = '';
//   words[1] = 'One';
//   words[2] = 'Two';
//   words[3] = 'Three';
//   words[4] = 'Four';
//   words[5] = 'Five';
//   words[6] = 'Six';
//   words[7] = 'Seven';
//   words[8] = 'Eight';
//   words[9] = 'Nine';
//   words[10] = 'Ten';
//   words[11] = 'Eleven';
//   words[12] = 'Twelve';
//   words[13] = 'Thirteen';
//   words[14] = 'Fourteen';
//   words[15] = 'Fifteen';
//   words[16] = 'Sixteen';
//   words[17] = 'Seventeen';
//   words[18] = 'Eighteen';
//   words[19] = 'Nineteen';
//   words[20] = 'Twenty';
//   words[30] = 'Thirty';
//   words[40] = 'Forty';
//   words[50] = 'Fifty';
//   words[60] = 'Sixty';
//   words[70] = 'Seventy';
//   words[80] = 'Eighty';
//   words[90] = 'Ninety';
//   var atemp = amount?.toString().split('.');
//   var number = atemp[0]?.split(',')?.join('');
//   var n_length = number?.length;
//   var words_string = '';
//   if (n_length <= 9) {
//     var n_array = new Array(0, 0, 0, 0, 0, 0, 0, 0, 0);
//     var received_n_array = new Array();
//     for (var i = 0; i < n_length; i++) {
//       received_n_array[i] = number.substr(i, 1);
//     }
//     for (var i = 9 - n_length, j = 0; i < 9; i++, j++) {
//       n_array[i] = received_n_array[j];
//     }
//     for (var i = 0, j = 1; i < 9; i++, j++) {
//       if (i == 0 || i == 2 || i == 4 || i == 7) {
//         if (n_array[i] == 1) {
//           n_array[j] = 10 + parseInt(n_array[j]);
//           n_array[i] = 0;
//         }
//       }
//     }
//     var value = '';
//     for (var i = 0; i < 9; i++) {
//       if (i == 0 || i == 2 || i == 4 || i == 7) {
//         value = n_array[i] * 10;
//       } else {
//         value = n_array[i];
//       }
//       if (value != 0) {
//         words_string += words[value] + ' ';
//       }
//       if (
//         (i == 1 && value != 0) ||
//         (i == 0 && value != 0 && n_array[i + 1] == 0)
//       ) {
//         words_string += 'Crores ';
//       }
//       if (
//         (i == 3 && value != 0) ||
//         (i == 2 && value != 0 && n_array[i + 1] == 0)
//       ) {
//         words_string += 'Lakhs ';
//       }
//       if (
//         (i == 5 && value != 0) ||
//         (i == 4 && value != 0 && n_array[i + 1] == 0)
//       ) {
//         words_string += 'Thousand ';
//       }
//       if (i == 6 && value != 0 && n_array[i + 1] != 0 && n_array[i + 2] != 0) {
//         words_string += 'Hundred and ';
//       } else if (i == 6 && value != 0) {
//         words_string += 'Hundred ';
//       }
//     }
//     words_string = words_string.split('  ').join(' ');
//   }
//   words_string += 'Rupees Only';
//   return words_string;
// }

export function convertNumToWords(amount) {
  var words = new Array();
  words[0] = '';
  words[1] = 'One';
  words[2] = 'Two';
  words[3] = 'Three';
  words[4] = 'Four';
  words[5] = 'Five';
  words[6] = 'Six';
  words[7] = 'Seven';
  words[8] = 'Eight';
  words[9] = 'Nine';
  words[10] = 'Ten';
  words[11] = 'Eleven';
  words[12] = 'Twelve';
  words[13] = 'Thirteen';
  words[14] = 'Fourteen';
  words[15] = 'Fifteen';
  words[16] = 'Sixteen';
  words[17] = 'Seventeen';
  words[18] = 'Eighteen';
  words[19] = 'Nineteen';
  words[20] = 'Twenty';
  words[30] = 'Thirty';
  words[40] = 'Forty';
  words[50] = 'Fifty';
  words[60] = 'Sixty';
  words[70] = 'Seventy';
  words[80] = 'Eighty';
  words[90] = 'Ninety';
  var atemp = amount?.toString().split('.');
  var number = atemp?.[0]?.split(',')?.join('');
  var n_length = number?.length;
  var words_string = '';
  if (n_length <= 9) {
    var n_array = new Array(0, 0, 0, 0, 0, 0, 0, 0, 0);
    var received_n_array = new Array();
    for (var i = 0; i < n_length; i++) {
      received_n_array[i] = number.substr(i, 1);
    }
    for (var i = 9 - n_length, j = 0; i < 9; i++, j++) {
      n_array[i] = received_n_array[j];
    }
    for (var i = 0, j = 1; i < 9; i++, j++) {
      if (i == 0 || i == 2 || i == 4 || i == 7) {
        if (n_array[i] == 1) {
          n_array[j] = 10 + parseInt(n_array[j]);
          n_array[i] = 0;
        }
      }
    }
    var value = '';
    for (var i = 0; i < 9; i++) {
      if (i == 0 || i == 2 || i == 4 || i == 7) {
        value = n_array[i] * 10;
      } else {
        value = n_array[i];
      }
      if (value != 0) {
        words_string += words[value] + ' ';
      }
      if (
        (i == 1 && value != 0) ||
        (i == 0 && value != 0 && n_array[i + 1] == 0)
      ) {
        words_string += 'Crores ';
      }
      if (
        (i == 3 && value != 0) ||
        (i == 2 && value != 0 && n_array[i + 1] == 0)
      ) {
        words_string += 'Lakhs ';
      }
      if (
        (i == 5 && value != 0) ||
        (i == 4 && value != 0 && n_array[i + 1] == 0)
      ) {
        words_string += 'Thousand ';
      }
      if (i == 6 && value != 0 && n_array[i + 1] != 0 && n_array[i + 2] != 0) {
        words_string += 'Hundred and ';
      } else if (i == 6 && value != 0) {
        words_string += 'Hundred ';
      }
    }
    words_string = words_string.split('  ').join(' ');
  }
  words_string += 'Rupees';

  if (atemp[1]) {
    var paise = atemp?.[1]?.slice(0, 2); // consider only first two digits for paise
    if (parseInt(paise) > 0) {
      words_string += ' ' + convertPaiseToWords(paise) + ' Paise';
    }
  }
  words_string += ' Only';
  return words_string;
}

function convertPaiseToWords(paise) {
  var words = new Array();
  words[0] = '';
  words[1] = 'One';
  words[2] = 'Two';
  words[3] = 'Three';
  words[4] = 'Four';
  words[5] = 'Five';
  words[6] = 'Six';
  words[7] = 'Seven';
  words[8] = 'Eight';
  words[9] = 'Nine';
  words[10] = 'Ten';
  words[11] = 'Eleven';
  words[12] = 'Twelve';
  words[13] = 'Thirteen';
  words[14] = 'Fourteen';
  words[15] = 'Fifteen';
  words[16] = 'Sixteen';
  words[17] = 'Seventeen';
  words[18] = 'Eighteen';
  words[19] = 'Nineteen';
  words[20] = 'Twenty';
  words[30] = 'Thirty';
  words[40] = 'Forty';
  words[50] = 'Fifty';
  words[60] = 'Sixty';
  words[70] = 'Seventy';
  words[80] = 'Eighty';
  words[90] = 'Ninety';

  var paise_words = '';

  if (paise.length === 1) {
    // paise = '0' + paise; // Adding a leading zero if only one digit is provided for paise
    paise = paise + '0'; // Adding a trailing zero if only one digit is provided for paise
  }

  var paiseValue = parseInt(paise);

  if (paiseValue <= 20) {
    paise_words += words[paiseValue];
  } else {
    paise_words += words[parseInt(paise[0]) * 10];
    paise_words += ' ' + words[parseInt(paise[1])];
  }

  return paise_words;
}

export function calculateMultiplier(productNameWidth, branchType, orientation) {
  const knownPercentage1 = 25;
  const knownPercentage2 = 30;
  let knownMultiplier1 = 1.19;
  let knownMultiplier2 = 1.25;

  if (branchType === 1 && orientation === 0) {
    knownMultiplier1 = 0.97;
    knownMultiplier2 = 0.97;
  } else if (branchType === 1 && orientation === 1) {
    knownMultiplier1 = 1.4;
    knownMultiplier2 = 1.4;
  }

  // Calculate the slope (rate of change) of the line
  const slope =
    (knownMultiplier2 - knownMultiplier1) /
    (knownPercentage2 - knownPercentage1);

  // Calculate the multiplier for the given percentage using linear interpolation
  const multiplier =
    knownMultiplier1 + slope * (productNameWidth - knownPercentage1);

  return !isNaN(multiplier) ? multiplier : 1.19;
}

export function getTableCellHeight(
  productCharCount,
  productNameWidth,
  orientation,
  branchType,
) {
  let defaultCellHeight =
    branchType === 1 ? 14 : branchType === 2 && orientation ? 14 : 10.5;

  let productWidthPercentage = parseFloat(
    parseFloat(
      productNameWidth *
        calculateMultiplier(productNameWidth, branchType, orientation),
    )?.toFixed(2),
  );

  let cellHeight =
    productCharCount == 0
      ? branchType == 1
        ? 14
        : 10.5
      : Math.ceil(
          Math.ceil(productCharCount / productWidthPercentage) *
            defaultCellHeight,
        );

  return !isNaN(cellHeight)
    ? branchType === 1
      ? cellHeight > 14
        ? 28
        : 14
      : cellHeight
    : 1;
}
